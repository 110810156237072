/*
 * Expressen mobile app will render maisha sites in a inappwebview.
 * But for example we we want login and purchases to be handled native in the app.
 * We do that here by using the expNative-bridge injected by the app in the webview.
 */
export default function init(scope) {
  if (!window.expNative) return;
  const container = scope.getElementsByClassName("paywall-manager__fallback")[0];
  if (!container) return;
  const [purchaseButton, loginButton] = container.getElementsByTagName("a");
  overridePurchaseButton(purchaseButton);
  overrideLoginButton(loginButton);
}
function overridePurchaseButton(purchaseButton) {
  purchaseButton.addEventListener("click", onPurchaseButtonClick);
  function onPurchaseButtonClick(event) {
    event.preventDefault();
    window.expNative.showNativePaywall(window.location.href);
  }
}
function overrideLoginButton(loginButton) {
  loginButton.addEventListener("click", onLoginButtonClick);
  function onLoginButtonClick(event) {
    event.preventDefault();
    window.expNative.loginWithExpressenAccount();
  }
}